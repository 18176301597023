import React from 'react';
import PropTypes from 'prop-types';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import styles from './empty-space.module.scss';

const toPixel = value => `${value}px`;
const heightMobile = 2;
const heightDesktop = 6;

const EmptySpace = ({ desktop, mobile }) => {
  const breakpoints = useBreakpoint();
  const config = (breakpoints.mobile || breakpoints.tablet) ? { ...mobile } : { ...desktop };

  return (
    <div
      className={styles.container}
      style={{
        display: config.isHidden ? 'none' : 'block',
        marginTop: toPixel(Math.floor(config.margin / 2)),
        marginBottom: toPixel(Math.floor(config.margin / 2)),
        visibility: config.isLineVisible ? 'visible' : 'hidden',
        height: breakpoints.mobile ? toPixel(heightDesktop) : toPixel(heightMobile),
      }}
    ></div>
  );
};

EmptySpace.defaultProps = {
  desktop: {
    isLineVisible: false,
    margin: 140,
    isHidden: false,
  },
  mobile: {
    isLineVisible: false,
    margin: 40,
    isHidden: false,
  },
};

EmptySpace.propTypes = {
  desktop: PropTypes.shape({
    isLineVisible: PropTypes.bool,
    isHidden: PropTypes.bool,
    margin: PropTypes.number,
  }),
  mobile: PropTypes.shape({
    isLineVisible: PropTypes.bool,
    isHidden: PropTypes.bool,
    margin: PropTypes.number,
  }),
};

export default EmptySpace;
