import React from 'react';
import PropTypes from 'prop-types';
import styles from './action-link.module.scss';
import { Link } from 'gatsby';

const ActionLink = (props) => {

  if (props.externalPath) {
    return (
      <a
        href={props.externalPath}
        className={`${styles.actionLink} ${props.type === 'dark' ? styles.dark : ''}`}
        style={{ width: props.width }}
        target="_blank"
        rel="noreferrer"
      >
        {props.label}
      </a>
    );
  }

  return (
    <Link
      to={props.path}
      className={`${styles.actionLink} ${props.type === 'dark' ? styles.dark : ''}`}
      style={{ width: props.width }}
    >
      {props.label}
    </Link>
  );
};

export default ActionLink;

ActionLink.defaultProps = {
  path: '',
  externalPath: '',
  label: '',
  width: 'auto',
};

ActionLink.propTypes = {
  path: PropTypes.string,
  externalPath: PropTypes.string,
  label: PropTypes.string,
  width: PropTypes.string,
  type: PropTypes.string,
};
