import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import styles from './footer.module.scss';
import Logotype from '@components/ui/logotype';
import SubscribeForm from '@components/ui/subscribe-form';
import EmptySpace from '@components/ui/empty-space';
import Contacts from '@components/ui/contacts';
import { config } from '@fortawesome/fontawesome-svg-core';
import "@fortawesome/fontawesome-svg-core/styles.css";
config.autoAddCss = false;
// TODO: uncomment when social network links are available
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';

const currentYear = new Date().getFullYear();

const Footer = (props) => {
  return (
  <footer className={styles.footer} style={{ paddingBottom: props.extraFooterPadding }}>
    <div className={styles.container}>

      <section className={styles.top}>
        <section>
          <Logotype isLightStyle={true} />
          <Contacts isLightStyle={true} />
        </section>

        <section className={styles.navLinks}>
          <ul>
            <li><Link to="/cursos/">cursos</Link></li>
            <li><Link to="/sobre-nos/">sobre nós</Link></li>
            <li><Link to="/perguntas-frequentes/">perguntas frequentes</Link></li>
            <li><Link to="/termos-e-condicoes/">termos e condições</Link></li>
            <li><Link to="/politica-de-privacidade/">política de privacidade</Link></li>
          </ul>
        </section>

        <section>
          <div>subscreve a nossa newsletter</div>
          <SubscribeForm id="footer-subscribe-form" />
        </section>
      </section>

      <EmptySpace desktop={{ margin: 100, isLineVisible: true }} mobile={{ margin: 80, isLineVisible: true }} />
      
      <section className={styles.bottom}>
        <div className={styles.copyrights}>
          <span>&copy; {currentYear} by <a className={styles.secondLink} href="https://www.treetree2.org/" target="_blank" rel="noreferrer">treetree2</a></span>
        </div>

        {/* TODO: uncomment when social network links are available */}
        {/* 
        <div className={styles.socialMedia}>
          <a href="https://www.google.com/" target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faFacebookF} color="white" size="2x" style={ { height: '24px' } } />
          </a>
          <a href="https://www.google.com/" target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faInstagram} color="white" size="2x" style={ { height: '24px' } } />
          </a>
        </div>
        */}
      </section>
    </div>
  </footer>
  );
};

export default Footer;

Footer.defaultProps = {
  extraFooterPadding: 0,
};

Footer.propTypes = {
  extraFooterPadding: PropTypes.number,
};
