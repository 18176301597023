import React from 'react';
import PropTypes from 'prop-types';
import styles from './contacts.module.scss';

const emailAddress = 'afterschool@treetree2.org';

const Contacts = (props) => (
  <div className={`${styles.container} ${props.isLightStyle ? styles.lightStyle : ''}`}>
    <span className={styles.content}>
      contacta-nos em &nbsp;
      {!props.isOneLine && (<br/>)}
      <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
    </span>
  </div>
);

export default Contacts;

Contacts.defaultProps = {
  isOneLine: false,
  isLightStyle: false,
};

Contacts.propTypes = {
  isOneLine: PropTypes.bool,
  isLightStyle: PropTypes.bool,
};
