import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import Logotype from '@components/ui/logotype';
import Contacts from '@components/ui/contacts';
import ActionLink from '@components/ui/action-link';
import AnimatedMenu from './animated-menu';
import MobileSession from '@components/domain/shared/auth/MobileSession';
import styles from './index.module.scss';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { config } from '@fortawesome/fontawesome-svg-core';
import "@fortawesome/fontawesome-svg-core/styles.css";
config.autoAddCss = false;
// TODO: uncomment when social network links are available
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons'

const MobileNavigationMenu = (props) => {
  const [isOpen, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (isOpen) {
      disableBodyScroll(document.querySelector('body'));
    } else {
      enableBodyScroll(document.querySelector('body'));
    }
  }, [isOpen]);

  return (
    <div className={`${styles.container} ${props.className}`}>
      <div className={`${styles.overlay} ${isOpen ? '' : styles.hidden}`}>

        <div className={styles.overlayContent}>
            <section className={styles.auth}>
              <MobileSession />
            </section>

            <section className={styles.linkList}>
              <nav>
                <ul>
                  <li><Link to="/" >Home</Link></li>
                  <li><Link to="/cursos/">Cursos</Link></li>
                  <li><Link to="/sobre-nos/">Sobre Nós</Link></li>
                </ul>
              </nav>
            </section>

            <section className={styles.contacts}>
              <Contacts />
            </section>

            {/* TODO: uncomment when social network links are available */}
            {/* <section className={styles.socialMedia}>
              <div>
                <a href="https://www.google.com/" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon
                    icon={faFacebookF}
                    color="black"
                    size="2x"
                    style={{ height: '24px' }}
                  />
                </a>
                <a href="https://www.google.com/" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon
                    icon={faInstagram}
                    color="black"
                    size="2x"
                    style={ { height: '24px' } }
                  />
                </a>
              </div>
            </section> */}
        </div>
      </div>

      <div className={styles.navBar}>
        <div className={styles.leftSide}>
          <AnimatedMenu isOpen={isOpen} handleClick={() => setOpen(!isOpen)} />
          <Logotype />
        </div>
        <div className={styles.rightSide} style={{ visibility: props.isNavLinksHidden ? 'hidden' : 'visible' }}>
          <ActionLink path="/candidatura/" label="candidata-te" width="115px" type="dark" />
        </div>
      </div>
    </div>
  );
};

export default MobileNavigationMenu;

MobileNavigationMenu.defaultProps = {
  className: '',
  isNavLinksHidden: false,
};

MobileNavigationMenu.propTypes = {
  className: PropTypes.string,
  isNavLinksHidden: PropTypes.bool,
};
