import React from 'react';
import AjaxLoader from "@images/domain/homepage/ajax-loader.gif";

const Spinner = () => {
  return (
    <img src={AjaxLoader} />
  );
};

export default Spinner;
