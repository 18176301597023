import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styles from './navigation-button.module.scss';

const NavigationButton = (props) => (
  <div className={styles.container}>
    <Link activeClassName={styles.activeNavLink} to={props.path}>{props.label}</Link>
  </div>
);

export default NavigationButton;

NavigationButton.defaultProps = {
  path: '/',
  label: '',
};

NavigationButton.propTypes = {
  path: PropTypes.string,
  label: PropTypes.string,
};
