import React from 'react';
import PropTypes from 'prop-types';
import Footer from '@components/domain/shared/footer';
import MobileNavigationMenu from '@components/domain/shared/mobile-navigation-menu';
import DesktopNavigationMenu from '@components/domain/shared/desktop-navigation-menu';
import CookieBanner from '@components/ui/cookie-banner';
import styles from './layout.module.scss';
import LazyLoad from 'react-lazyload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { config } from '@fortawesome/fontawesome-svg-core';
import "@fortawesome/fontawesome-svg-core/styles.css";
config.autoAddCss = false;

const Layout = (props) => {
  return (
    // TODO: read this https://www.gatsbyjs.org/docs/custom-html/
    <div id="layout">
      <CookieBanner />
      <div className={styles.container}>
        <main className={styles.content}>

          <MobileNavigationMenu className={styles.mobileMenu} {...props} />
          <DesktopNavigationMenu className={styles.desktopMenu} {...props} />

          {props.sideNote &&
          <div className={styles.sideNote}>
            <FontAwesomeIcon icon={faArrowLeft} size="xs" />
            <h1>{props.sideNote}</h1>
          </div>}

          {props.children}
        </main>
      </div>

      {!props.isFooterHidden &&
        <LazyLoad height={500} once>
          <Footer extraFooterPadding={props.extraFooterPadding} />
        </LazyLoad>
      }
      

    </div>
  );
};

export default Layout;

Layout.defaultProps = {
  sideNote: null,
  isFooterHidden: false,
  isNavLinksHidden: false,
};

Layout.propTypes = {
  children: PropTypes.node,
  sideNote: PropTypes.string,
  isFooterHidden: PropTypes.bool,
  isNavLinksHidden: PropTypes.bool,
  extraFooterPadding: PropTypes.number,
};
