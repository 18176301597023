
import React from 'react';
import styles from './cookie-banner.module.scss';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import CookieConsent, { Cookies } from "react-cookie-consent";

const CookieBanner = () => {
  const breakpoints = useBreakpoint();

  const messageStyle = breakpoints.mobile ? {} : { marginLeft: 95, maxWidth: 600 };
  
  const bannerBaseStyle = {
    background: "#282828",
    fontSize: 12,
    color: 'white',
    borderRadius: 10,
    alignItems: 'center',
  };
  
  const buttonBaseStyle = {
    fontSize: 12,
    padding: '11px 24px',
    width: 111,
    borderRadius: 10,
    backgroundColor: '#ffffff',
  };

  const bannerStyle = { ...bannerBaseStyle, ...(breakpoints.mobile ? { flexDirection: 'column' } : {})};
  
  const buttonStyle = { ...buttonBaseStyle,
    ...(breakpoints.mobile ?
      {
        margin: '0 0 15px 0',
        padding: '18px 30px',
        fontSize: 15,
        width: '100%',
      } :
      {
        marginRight: 95,
      })
    };

  return (
    <CookieConsent
      onAccept={() => Cookies.set('gatsby-plugin-google-analytics-gdpr_cookies-enabled', true)}
      onDecline={() => Cookies.set('gatsby-plugin-google-analytics-gdpr_cookies-enabled', false)}
      containerClasses={styles.container}
      buttonText="aceitar"
      style={bannerStyle}
      buttonStyle={buttonStyle}
    >
      <p style={messageStyle}>
        Este site utiliza cookies para melhorar a funcionalidade e a sua experiência 
        de utilização do mesmo. Ao clicares em “Aceitar”, concordas com a utilização 
        das cookies.
      </p>
    </CookieConsent>
  );
};

export default CookieBanner;

