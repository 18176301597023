import React, { useState }  from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { config } from '@fortawesome/fontawesome-svg-core';
import "@fortawesome/fontawesome-svg-core/styles.css";
import styles from './subscribe-form.module.scss';
config.autoAddCss = false;

const SubscribeForm = (props) => {
  const inputRef = React.useRef(null);
  const formRef = React.useRef(null);
  const [isConfirmed, setConfirmation] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  /**
   * Checkbox event handlers
   */
  const toggleCheckbox = () => {
    setShowWarning(false);
    setConfirmation(!isConfirmed);
  };

  const handleKeyDown = event => event.key === 'Enter' && toggleCheckbox();

  /**
   * Form submition handler
   */
  const handleSubmitButton = event => {
    event.preventDefault();

    if (!isConfirmed) {
      setShowWarning(true);
      return undefined;
    }

    setShowWarning(false);
    const formElement = formRef.current;

    if (formElement && formElement.submit) {
      formRef.current.submit();
    }

    cleanForm();
  };

  const cleanForm = () => {
    const inputField = inputRef.current;
    if (inputField && inputField.value) {
      inputField.value = '';
    }

    setConfirmation(false);
  };

  return (
    <form ref={formRef} action="https://treetree2.us16.list-manage.com/subscribe/post" method="post" target="_blank" noValidate>

      <input type="hidden" name="u" value="4aa39792495e04a7e9dafcee0" />
      <input type="hidden" name="id" value="b7ffd84939" />

      <div className={styles.emailBar}>
        <input ref={inputRef} name="EMAIL" type="email" placeholder="o teu email" aria-label="email" className={styles.emailInput} />
        <button onClick={handleSubmitButton} aria-label="submit email" type="submit" className={styles.emailSubmit}>
          <FontAwesomeIcon icon={faArrowRight} color="black" />
        </button>
      </div>

      <div className={styles.confirmation}>
        {/* Checkbox */}
        <input onChange={toggleCheckbox} type="checkbox" id={props.id} checked={isConfirmed} />
        
        {/* Label for the checkbox */}
        <label htmlFor={props.id}>
          <span
            aria-checked={isConfirmed}
            aria-label="checkbox"
            className={`${styles.customCheckbox} ${showWarning ? styles.warning : ''}`}
            onChange={toggleCheckbox}
            onKeyDown={handleKeyDown}
            role="checkbox"
            tabIndex="0"
          >
          </span>
          <span className={styles.declaration}>
            Declaro que li e aceito os &nbsp;
            {<Link to="/termos-e-condicoes/">Termos e Condições</Link>}&nbsp;
            e a {<Link to="/politica-de-privacidade/">Política de Privacidade</Link>}.
          </span>
        </label>

        {/* Error message */}
        <span
          style={{ visibility: showWarning ? 'visible' : 'hidden' }}
          className={styles.error}
        >
          Por favor, leia e aceite os Termos e Condições e a Política de Privacidade.
        </span>
      </div>
    </form>
  );
};

export default SubscribeForm;

SubscribeForm.defaultProps = {
  id: 'confirmation',
};

SubscribeForm.propTypes = {
  id: PropTypes.string,
};
