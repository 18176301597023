import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import Spinner from '@components/ui/spinner';
import styles from './mobile-session.module.scss';

// fontawesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { config } from '@fortawesome/fontawesome-svg-core';
import "@fortawesome/fontawesome-svg-core/styles.css";
config.autoAddCss = false;

const MobileSession = () => {
  const { isLoading, isAuthenticated, loginWithRedirect, logout } = useAuth0();

  if (isLoading) {
    return (
      <div className={styles.container}>
        <Spinner />
      </div>
    );
  }

  if (isAuthenticated) {
    return (
      <div className={styles.container}>
        {/* <span>Olá {user.name},</span> */}
        <a className={styles.red} onClick={() => logout({ returnTo: process.env.GATSBY_AUTH0_CALLBACK_URL })}>sair <FontAwesomeIcon icon={faSignOutAlt} /></a>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <span>sem sessão iniciada</span>
      <a onClick={() => loginWithRedirect()}>entrar</a>
    </div>
  );
};

export default MobileSession;
