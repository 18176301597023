import React from 'react';
import PropTypes from 'prop-types';
import styles from './logotype.module.scss';
import { navigate } from "gatsby";

const Logotype = (props) => {

  return (
    <div className={`${styles.container} ${props.isLightStyle ? styles.lightStyle : ''}`}>
      <a onClick={() => navigate("/")} className={styles.firstLink} target="_blank" rel="noreferrer">afterschool</a>
      <section className={styles.rightSide}>
        <span className={styles.linkSeparator}>by</span>
        <a className={styles.secondLink} href="https://www.treetree2.org/" target="_blank" rel="noreferrer">treetree2</a>
      </section>
    </div>
  );
};

export default Logotype;

Logotype.defaultProps = {
  isLightStyle: false,
};

Logotype.propTypes = {
  isLightStyle: PropTypes.bool,
};
