import React from 'react';
import PropTypes from 'prop-types';
import styles from './animated-menu.module.scss';

const AnimatedMenu = ({ isOpen, handleClick }) => {
  return (
    <div className={styles.container}>
      <button onClick={handleClick} className={`${styles.menu} ${isOpen ? styles.open : ""}`}>
        <span className={styles.symbol}></span>
      </button>
    </div>
  );
};

export default AnimatedMenu;

AnimatedMenu.defaultProps = {
  isOpen: false,
  handleClick: () => {},
};

AnimatedMenu.propTypes = {
  isOpen: PropTypes.bool,
  handleClick: PropTypes.func,
};
